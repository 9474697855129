<template>
  <div class="menu">
    <!-- está logueado -->
    <template v-if="logged">
      <b-navbar toggleable="lg" type="light" variant="faded">
        <router-link :to="{name:'inicio'}">
          <b-navbar-brand><img :src="logotipo_b64" height="90px;"></b-navbar-brand>
        </router-link>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right v-if="isAdmin">
              <template v-slot:button-content>
                <b-icon icon="people-fill" class="mr-2" style="color:#00c5b4;"></b-icon>gest. Usuarios
              </template>
              <b-dropdown-item :to="{ name:'usuarios' }">Usuarios</b-dropdown-item>
              <b-dropdown-item @click="logAcceso">Logs de acceso</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item :to="{ name:'proveedores' }">
              <b-icon icon="basket3" class="mr-2" style="color:#00c5b4;"></b-icon>Proveedores
            </b-nav-item>
            <b-nav-item :to="{ name:'pagos' }">
              <b-icon icon="file-earmark-ruled" class="mr-2" style="color:#00c5b4;"></b-icon>Pagos
            </b-nav-item>
            <b-nav-item :to="{ name:'ayuda' }">
              <b-icon icon="telephone-outbound-fill" class="mr-2" style="color:#00c5b4;"></b-icon>Asistencia técnica
            </b-nav-item>
            <!-- sesión -->
            <b-nav-item-dropdown right>
              <template v-slot:button-content>
                <!-- <b-avatar size="lg" :src="gravatar"></b-avatar> -->
                <b-icon icon="person-bounding-box" class="mr-2" style="color:#00c5b4;"></b-icon>{{ usrLogin.nombre }}
              </template>
              <b-dropdown-item :to="{ name:'cambiopsw' }">Cambiar contraseña</b-dropdown-item>
              <b-dropdown-item @click="cerrarSesion">Cerrar sesión</b-dropdown-item>
            </b-nav-item-dropdown>
            <!-- fin/sesión -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar> 
    </template>

    <!-- no está logueado y/o no está verificado el email -->
    <template v-else>
      <b-navbar toggleable="lg" type="light" variant="faded">
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
              <b-nav-text>
                <span style="font-size:2rem" class="m-0">
                  <b-icon icon="person-fill" class="rounded-circle bg-danger p-1" variant="light"></b-icon>
                </span>
              </b-nav-text>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </template>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex';
  import { jsPDF } from "jspdf";
  export default {
    name: 'Cabecera',
    data() {
      return {
      }
    },
    computed: {
      ...mapState('auth',['usrLogin', 'logged']),
      ...mapState(['logotipo_b64', 'logs']),

      isAdmin: function(){
        return this.usrLogin.roll=='adm' ? true : false
      },
      // gravatar(){
      //   return `https://www.gravatar.com/avatar/${this.usrLogin.email_md5}.jpg?s=45`;
      // }
    },

    methods:{
      ...mapActions('auth', ['cerrarSesion']),
      ...mapMutations(['setLoading']),

      logAcceso() {
        const doc = new jsPDF();
        let row = 35;
        let col;
        let pag = 1;
        doc.addImage(this.logotipo_b64, "PNG", 60, 10, 85, 15, null);
        col = 55;
        doc.setFontSize(20);
        doc.text(' -- Logs de acceso mensual --', col, row);
        col = 17;
        row += 15;
        //cabecera
        doc.setFontSize(10);
        doc.text('TimeStamp                 Log           mail                                                       Usuario                                      IP', col, row);
        row += 5;
        doc.text('=========================================================================================', col, row);
        row += 8;
        doc.setFontSize(9);
        this.logs.forEach(item => {
          //líneas de detalle
          if (row < 260) {
            doc.text(`${item.timestamp}      ${item.access_type}`, col, row);
            col = 67;
            doc.text(`${item.email}`, col, row);
            col = 128;
            doc.text(`${item.nombre}`, col, row);
            col = 175;
            doc.text(`${item.IP}`, col, row);
            col = 17;
            row += 5;
          } else {
            col = 100;
            row = 280;
            doc.text(`Pág. ${pag}`, col, row);
            doc.addPage();
            doc.setFontSize(10);
            row = 25;
            col = 17;
            doc.text('TimeStamp                 Log          Email                                                       Usuario                                     IP', col, row);
            row += 5;
            doc.text('=========================================================================================', col, row);
            row += 8;
            doc.setFontSize(9);
            col = 17;
            row = 35;
            pag += 1;
          }
        });
        col = 100;
        row = 280;
        doc.text(`Pág. ${pag}`, col, row);
        doc.output('dataurlnewwindow', { filename: 'logs.pdf' });
      },

    },
    
  }
</script>