import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import Swal from 'sweetalert2';
Vue.use(Vuex);
const namespaced = true
const Confirm = Swal.mixin({
  title: '¿Está seguro?',
  icon: 'question',
  showConfirmButton: true,
  confirmButtonColor: '#3085d6',
  showCancelButton: true,
  cancelButtonColor: '#d33',
  cancelButtonText: 'Cancelar',
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

export default {
  namespaced,

  state: {
    // logged: !!localStorage.getItem('_token'),
    logged: false,
    usrLogin: {
      id: 0,
      email: '',
      nombre: '',
      roll: '',
      token: '',
      secretKey: '',
      IP: ''
    },
  },


  /*
  |===================================================================
  | Actions: 
  |===================================================================
  */
  actions: {
    async getLocalIPAddress({ commit }) {
      const response = await fetch("https://api.ipify.org/?format=json");
      const data = await response.json();
      const localIPAddress = data.ip;
      commit('setIP', localIPAddress);
    },

    login({ commit }, payload) {
      commit('setLoading', true, { root: true });
      
      return Vue.axios({
          method: 'post',
          url: 'auth/login',
          data: {
              email: payload.email,
              password: payload.password
          },
          headers: {
              'Content-Type': 'application/json'
          },
          withCredentials: false  // Cambiado a false
      })
      .then(response => {
          console.log('Respuesta:', response);
          if (response.status === 200) {
              const session_user = response.data.sesion;
              commit('setLogged', true);
              commit('setUsrLogin', session_user);
              router.push({ name: 'inicio' });
          }
      })
      .catch(error => {
          console.error('Error completo:', error);
          commit('setLogged', false);
          Swal.fire("Error de login", "Error al iniciar sesión", "error");
      })
      .finally(() => {
          commit('setLoading', false, { root: true });
      });
    },

    cerrarSesion({commit, state}){
      Vue.axios
      .get(`auth/logoff/${state.usrLogin.id}`)
      .then((respuesta) => {
        const logged = respuesta.data.logoff;
        console.log(logged);
        if (logged) {
          localStorage.removeItem('_token');
          commit('setLogged', false);
          commit('setUsrLogin', {
            id: '',
            email: '',
            nombre: '',
            roll: '',
            token: '',
            secretKey: '',
            IP: '',
          });
          router.push({name: 'login'});
        }        
      })
    },


    cambiarPsw({commit, state, dispatch}) { 
      commit('setLoading', true, {root: true})
      const formulario = new FormData(document.getElementById('frmCambioPsw'));
      console.log(state.usrLogin.token);
      Vue.axios
      .post(`auth/change_password/${state.usrLogin.id}`, formulario)
      .then((respuesta) => {
        const res = respuesta.data.code_state;
        if(res == 200) {
          commit('setLoading', false, {root: true});
          Swal.fire(`¡Enhorabuena ${state.usrLogin.nombre}!`, "La contraseña ha sido cambiada con éxito", "success");
          dispatch('cerrarSesion');
          // router.push({name: 'inicio'});
        } else {
          commit('setLoading', false, {root: true});
          Swal.fire("Error", "Ha habido un problema al intentar cambiar la contraseña... ¡Inténtelo de nuevo!", "error");
        }
      })
      .catch(error => {
        commit('setLoading', false, {root: true});
        Swal.fire("Error", "Ha habido un problema al intentar cambiar la contraseña... ¡Inténtelo de nuevo!", "error");
      })
    },

    rec_pass({commit, state, dispatch}, email){
      Confirm.fire({
        text: "Una vez que confirme la recuperación de la contraseña, recibirá una nueva en su cuenta de correo, y luego podrá cambiarla...",
        confirmButtonText: 'Confirmar',
      })
      .then((result) => {
        if(result.value) {
          commit('setLoading', true, {root: true})
          const formulario = new FormData();
          formulario.append('email', email);
          Vue.axios
          .post(`auth/rec_password`, formulario)
          .then((respuesta) => {
            commit('setLoading', false, {root: true});
            const res = respuesta.data.code_state;
            if (res == 200) {
              Swal.fire(`¡Enhorabuena ${state.usrLogin.nombre}!`, "Ha recibido la nueva contraseña en su cuenta de correo.", "success");
              dispatch('cerrarSesion');
              // router.push({name: 'inicio'});
            } else {
              Swal.fire("Error", "Ha habido un problema al intentar recordar la contraseña... ¡Inténtelo de nuevo!", "error");
            }
          })
        }
      })
    }
  },

  /*
  |===================================================================
  | Mutations: 
  |===================================================================
  */
  mutations: {
    setLogged(state, logged) {
      state.logged = logged;
    },

    setUsrLogin(state, usrLogin) {
      state.usrLogin = usrLogin;
    },

    setIP(state, value) {
      state.usrLogin.IP = value;
    },
  }
}