<template>
  <div class="container text-center mt-3 mb-4">
    <div>  
      <span class="text-muted">Copyright &copy; {{ anyo }} SiiNet.com (SIINTERNET, SL.)</span>
      <span class="enlace ml-5" @click="abrirST()">
        <b-icon icon="telephone-outbound-fill" class="mr-1" style="color:#00c5b4;" />
        Soporte técnico
      </span> 
      <span class="enlace ml-5" @click="abrirPdf()">
        <b-icon icon="file-earmark" class="mr-1" style="color:#00c5b4;" />
        Datasheet (hoja de producto)
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import router from "@/router";
export default {
  computed: {
    ...mapState(["base"]),

    anyo(){
      let today = new Date();
      let year = today.getFullYear();
      return year; 
    }
  },  
  methods: {
    abrirPdf(){
      window.open(`${this.base}datasheet.pdf`, '_black');
    },
    abrirST(){
      router.push({ name: "ayuda" });
    },
  }

}
</script>
<style>
.enlace {
  cursor: pointer;
}
.enlace:hover {
  color: rgb(0, 156, 120);
}
</style>
