import Vue from 'vue';
import Router from 'vue-router';
import store from './../store';

Vue.use(Router)

const beforeEnter = (to, from, next) => {
  let logged = store.state.auth.logged;
  if (logged) {
    next({
      name: 'inicio'
    });
  } else {
    next();
  }
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/',
      name: 'inicio',
      component: () => import('./../views/Inicio.vue'),
      meta: {
        requiresAuth: true,
        title: 'Inicio'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./../views/Login.vue'),
      meta: {
        title: 'Iniciar sesión'
      },
      beforeEnter: (to, from, next) => beforeEnter(to, from, next)
    },
    {
      path: '/cambiopsw',
      name: 'cambiopsw',
      component: () => import('./../views/Cambio_psw.vue'),
      meta: {
        requiresAuth: true,
        title: 'Cambiar password'
      }
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      component: () => import('./../views/Usuarios.vue'),
      meta: {
        requiresAuth: true,
        title: 'Gestión de usuarios'
      }
    },
    {
      path: '/proveedores',
      name: 'proveedores',
      component: () => import('./../views/Proveedores.vue'),
      meta: {
        requiresAuth: true,
        title: 'Gestión de proveedores'
      }
    },
    {
      path: '/pagos',
      name: 'pagos',
      component: () => import('./../views/Pagos.vue'),
      meta: {
        requiresAuth: true,
        title: 'Gestión de pagos'
      }
    },
    {
      path: '/subir_ficheros',
      name: 'subir_ficheros',
      component: () => import('./../views/UploadFiles.vue'),
      meta: {
        requiresAuth: true,
        title: 'Guardar ficheros PDF'
      }
    },
    {
      path: '/ayuda',
      name: 'ayuda',
      component: () => import('../views/Ayuda.vue'),
      meta: {
        requiresAuth: true,
        title: 'Ayuda'
      }
    },
  ]
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  const rutaProtegida = to.matched.some(record => record.meta.requiresAuth);
  let logged = store.state.auth.logged;
  if (rutaProtegida) {
    if (logged == false) {
      next({
        name: 'login'
      });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
